import _ from "lodash";
import * as ACTION_TYPE from "./constants";
import * as USER_ACTION_TYPE from "../user/constants";
import moment from "../../utils/moment-timezone-with-data";
import { ORDER_STATE, getOrderState } from "../../utils/order";
import reduceByCouponsChecked from "../reduceByCouponsChecked";
import findBestCouponBatchIndex from "../findBestCouponBatchIndex";
import getCouponBatchesSelectionInitialState from "../getCouponBatchesSelectionInitialState";
import { LOGIN_TYPES } from "../../components/LoginView";

const initialState = {
  orderItems: [],
  deliveryAddress: null,
  deliveryTipPercentage: 0,
  deliveryTipCustomValue: null,
  servingOptionTipPercentage: 0,
  servingOptionTipCustomValue: null,
  silentSaving: false,
  placingOrder: false,
  orderPlaced: false,
  orderPlacedReportedToPixels: false,
  servingOptionType: null,
  servingOption: null,
  shouldSaveOrder: false,
  checkoutResponse: null,
  editingItem: null,
  pickupTime: null,
  reorder: false,
  saveOrderError: null,
  placeOrderError: null,
  savingRequestsTimestamps: [],
  lastChangeTimestamp: null,
  lastSaveTimestamp: null,
  editItemFromPayment: false,
  isChargeCardDeselected: false,
  fixedRemarks: [],
  futureServingTime: null,
  shouldCheckOrderStatus: false,
  shouldRequestUserToCheckOrderStatus: false,
  checkingOrderStatusDeadline: null,
  checkingOrderStatus: false,
  eligableForDiscount: false,
  aggregatorId: null,
  promoCodes: [],
  tableCode: null,
  placedOrderId: null,
  ...getCouponBatchesSelectionInitialState(),
  checkPaymentProcessing: null,
  checkPaymentError: null,
  lastCheckDetails: null,
  openPaymentTokens: null,
  shouldSyncOrderItems: true,
};

const orderDoneState = {
  placeOrderError: null,
  couponsBatchIndex: -1,
  couponsUncheckedByUser: [],
  couponsCheckedByUser: [],
  isChargeCardDeselected: false,
  checkoutResponse: null,
  deliveryTipPercentage: 0,
  servingOptionTipPercentage: 0,
  fixedRemarks: [],
  placingOrder: false,
  orderPlaced: true,
  orderPlacedReportedToPixels: false,
  orderItems: [],
  checkingOrderStatus: false,
  shouldRequestUserToCheckOrderStatus: false,
  shouldSyncOrderItems: false,
};

const UNKNOWN_ORDER_ERROR = `We are sorry, there is an unknown problem with your order. It was not approved,\
 maybe the menu was updated recently. Try modifying the items or create a new order`;

export default (state = initialState, action) => {
  switch (action.type) {
    case "REDUX_STORAGE_LOAD":
      console.log("REDUX_STORAGE_LOAD");
      return {
        ...state,
        // placingOrder: false,
        // orderPlaced: false,
        savingRequestsTimestamps: [],
        silentSaving: false,
        // saveOrderError: null,
        // placeOrderError: null,
        // checkingOrderStatus: false,
        // checkPaymentProcessing: null,
        // checkPaymentError: null,
      };

    case USER_ACTION_TYPE.VERIFY_CODE.SUCCESS: // LOGGED_IN succefully
      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        saveOrderError: null,
        placeOrderError: null,
        savingRequestsTimestamps: [],
        checkPaymentProcessing: null,
        checkPaymentError: null,
      };

    case USER_ACTION_TYPE.LOGOUT:
      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        savingRequestsTimestamps: [],
        saveOrderError: null,
        placeOrderError: null,
        shouldCheckOrderStatus: false,
        shouldRequestUserToCheckOrderStatus: false,
        checkPaymentProcessing: null,
        checkPaymentError: null,
      };
    case USER_ACTION_TYPE.TRADE_POINTS_FOR_COUPON.SUCCESS:
      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        saveOrderError: null,
        placeOrderError: null,
        savingRequestsTimestamps: [],
      };

    case ACTION_TYPE.START_NEW_ORDER:
      return {
        ...initialState,
        searchCode: state.searchCode,
        lastOrderDetails: state.lastOrderDetails,
      };

    case ACTION_TYPE.SET_PICKUP_TIME:
      const isUpdatedPickupTime =
        action.payload.pickupTime !== state.pickupTime ||
        !moment(action.payload.futureServingTime).isSame(
          state.futureServingTime,
          "day",
        );

      return {
        ...state,
        futureServingTime: action.payload.futureServingTime,
        pickupTime: action.payload.pickupTime,
        lastChangeTimestamp: isUpdatedPickupTime
          ? action.payload.timestamp
          : state.lastChangeTimestamp,
      };

    case ACTION_TYPE.SET_FUTURE_SERVING_TIME:
      const isUpdatedFutureServingTime = !moment(
        action.payload.futureServingTime,
      ).isSame(state.futureServingTime, "day");

      return {
        ...state,
        futureServingTime: action.payload.futureServingTime,
        pickupTime: isUpdatedFutureServingTime ? null : state.pickupTime,
        lastChangeTimestamp: isUpdatedFutureServingTime
          ? action.payload.timestamp
          : state.lastChangeTimestamp,
      };

    case ACTION_TYPE.RESET_ORDER_BRANCH_ID:
      return {
        ...state,
        branchId: null,
        pickupTime: null,
        futureServingTime: null,
      };

    case ACTION_TYPE.APPLY_PARAMS_TO_ORDER:
      return { ...state, ...action.payload };

    case ACTION_TYPE.SET_SERVING_OPTION:
      const isUpdatedServingOption =
        _.get(action.payload.servingOption, "id") !==
        _.get(state.servingOption, "id");
      if (action.payload.servingOption.type === "delivery") {
        return {
          ...state,
          mandatoryTextFields: [],
          servingOptionTipPercentage: 0,
          servingOption: action.payload.servingOption,
          servingOptionType: action.payload.servingOption.type,
          lastChangeTimestamp: isUpdatedServingOption
            ? action.payload.timestamp
            : state.lastChangeTimestamp,
          pickupTime: null,
          futureServingTime: isUpdatedServingOption
            ? null
            : state.futureServingTime,
        };
      } else {
        return {
          ...state,
          mandatoryTextFields: [],
          deliveryTipPercentage: 0,
          servingOption: action.payload.servingOption,
          servingOptionType: action.payload.servingOption.type,
          deliveryAddress: null,
          lastChangeTimestamp: isUpdatedServingOption
            ? action.payload.timestamp
            : state.lastChangeTimestamp,
          pickupTime: isUpdatedServingOption ? null : state.pickupTime,
          futureServingTime: isUpdatedServingOption
            ? null
            : state.futureServingTime,
        };
      }

    case ACTION_TYPE.SET_ORDER_DELIVERY_ADDRESS:
      console.log("deliveryAddress:", {
        ...state.deliveryAddress,
        ...action.payload.deliveryAddress,
      });
      return {
        ...state,
        deliveryAddress: {
          ...state.deliveryAddress,
          ...action.payload.deliveryAddress,
        },
        ...(!_.get(action, "payload.options.preventAutoUpdate") && {
          lastChangeTimestamp: action.payload.timestamp,
        }),
      };
    case ACTION_TYPE.SET_DELIVERY_TIP_PERCENTAGE:
      return {
        ...state,
        deliveryTipPercentage: action.payload.deliveryTipPercentage,
      };
    case ACTION_TYPE.SET_SERVING_OPTION_TIP_PERCENTAGE:
      return {
        ...state,
        servingOptionTipPercentage: action.payload.servingOptionTipPercentage,
      };
    case ACTION_TYPE.SET_CHOSEN_PAYMENT_TYPE:
      return {
        ...state,
        chosenPaymentType: action.payload.chosenPaymentType,
      };
    case ACTION_TYPE.ADD_TO_ORDER:
      return {
        ...state,
        orderItems: _.concat(_.clone(state.orderItems), action.payload.item),
        lastChangeTimestamp: action.payload.timestamp,
      };
    case ACTION_TYPE.ADD_ITEMS_TO_ORDER:
      return {
        ...state,
        orderItems: _.concat(_.clone(state.orderItems), ...action.payload.items),
        lastChangeTimestamp: action.payload.timestamp,
      };
    case ACTION_TYPE.REMOVE_FROM_ORDER:
      const orderItems = _.clone(state.orderItems);
      _.pullAt(orderItems, [action.payload.itemIndex]);

      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        checkoutResponse: null,
        orderItems,
      };
    case ACTION_TYPE.REMOVE_ALL_FROM_ORDER:
      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        checkoutResponse: null,
        orderItems: [],
      };
    case ACTION_TYPE.UPDATE_ITEM_QUANTITY:
      const updatedQtyInState = _.set(
        _.cloneDeep(state),
        `orderItems[${
          action.payload.itemIndex
        }].configuredMenuItemOrderData.count`,
        action.payload.count
      );
      return {
        ...updatedQtyInState,
        lastChangeTimestamp: action.payload.timestamp,
      };
    case ACTION_TYPE.START_ORDER_ITEM_EDIT:
      return {
        ...state,
        editingItem: {
          itemIndex: action.payload.itemIndex,
          itemId: action.payload.itemId,
        },
      };
    case ACTION_TYPE.CANCEL_ORDER_ITEM_EDIT:
      return {
        ...state,
        editingItem: null,
      };
    case ACTION_TYPE.APPLY_ORDER_ITEM_EDIT:
      const itemPath = `orderItems[${action.payload.itemIndex}]`;
      const preUpdateItem = _.get(state, itemPath);
      const addedFromUpsale = _.get(preUpdateItem, "addedFromUpsale");
      const updatedItemInState = _.set(_.cloneDeep(state),  itemPath, { ...action.payload.item, addedFromUpsale });
      return {
        ...updatedItemInState,
        editingItem: null,
        editItemFromPayment: false,
        lastChangeTimestamp: action.payload.timestamp,
      };
    case ACTION_TYPE.SET_BRANCH_ID_AND_NEXT_AVAILABLE_TIME:
      const isUpdatedBranchId = action.payload.branchId !== state.branchId;
      return {
        ...state,
        branchId: action.payload.branchId,
        lastChangeTimestamp: isUpdatedBranchId
          ? action.payload.timestamp
          : state.lastChangeTimestamp,
        pickupTime: action.payload.pickupTime || null,
        futureServingTime: action.payload.futureServingTime || null,
      };
    case ACTION_TYPE.SAVE_ORDER.STARTED:
      const savingRequestsTimestamps = [...state.savingRequestsTimestamps];
      savingRequestsTimestamps.push(action.payload.timestamp);

      return {
        ...state,
        savingRequestsTimestamps,
        saveOrderError: null,
        placeOrderError: null,
        placingOrder: false,
        orderPlaced: false,
        orderPlacedReportedToPixels: false,
        shouldRequestUserToCheckOrderStatus: false,
        shouldCheckOrderStatus: false,
        checkingOrderStatus: false,
      };
    case ACTION_TYPE.SAVE_ORDER.SUCCESS:
      const couponBatchesResponse = _.get(
        action.payload.checkoutResponse,
        "couponBatchesResponse",
      );
      const isNewerThanCurrent =
        action.payload.timestamp >= state.lastSaveTimestamp;

      return {
        ...state,
        shouldRequestUserToCheckOrderStatus: false,
        shouldCheckOrderStatus: false,
        checkingOrderStatus: false,
        giftCardsToRedeem: [],
        savingRequestsTimestamps: [],
        lastSaveTimestamp: isNewerThanCurrent
          ? action.payload.timestamp
          : state.lastSaveTimestamp,
        saveOrderError: null,
        checkoutResponse: isNewerThanCurrent
          ? action.payload.checkoutResponse
          : state.checkoutResponse,
        couponsBatchIndex: couponBatchesResponse || !action.payload.isSignUpOnPayment
          ? findBestCouponBatchIndex({
              ...couponBatchesResponse,
              ...state,
            })
          : -1,
        ...(isNewerThanCurrent && {
          promoCodes: _.get(
            action.payload.checkoutResponse,
            "order.promoCodes",
          ),
          prepTime: _.get(action.payload.checkoutResponse, "order.prepTime"),
          deliveryTimeDuration: _.get(action.payload.checkoutResponse, "order.deliveryTimeDuration"),
        }),
      };
    case ACTION_TYPE.SAVE_ORDER.FAILED:
      return {
        ...state,
        shouldRequestUserToCheckOrderStatus: false,
        shouldCheckOrderStatus: false,
        checkingOrderStatus: false,
        savingRequestsTimestamps: _.without(
          state.savingRequestsTimestamps,
          action.payload.timestamp,
        ),
        saveOrderError:
          action.payload.timestamp > state.lastSaveTimestamp
            ? action.payload.error
            : state.error,
        checkoutResponse: null,
      };
    case ACTION_TYPE.SET_EXECUTE_PAYMENT:
      return {
        ...state,
        executePayment: action.payload.executePayment,
      };
    case ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.STARTED:
    case ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.STARTED:
    case ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.STARTED:
      return {
        ...state,
        placedOrderId: _.get(state, "checkoutResponse.order.id"),
        placingOrder: true,
        orderPlaced: false,
        orderPlacedReportedToPixels: false,
        placeOrderError: null,
        shouldRequestUserToCheckOrderStatus: false,
        shouldCheckOrderStatus: false,
        checkingOrderStatus: false,
        executePayment: false,
      };
    case ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.SUCCESS:
    case ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.SUCCESS:
    case ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.SUCCESS:
      return {
        ...state,
        ...orderDoneState,
        lastOrderDetails: {
          ...action.payload,
          couponsBatchIndex: state.couponsBatchIndex,
          checkoutResponse: state.checkoutResponse,
          orderItems: state.orderItems,
          isChargeCardDeselected: state.isChargeCardDeselected,
          deliveryTipPercentage: state.deliveryTipPercentage,
          servingOptionTipPercentage: state.servingOptionTipPercentage,
        },
        executePayment: false,
      };
    case ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.FAILED:
    case ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.FAILED:
    case ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED:
      return {
        ...state,
        placingOrder: Boolean(action.payload.shouldCheckOrderStatus),
        shouldRequestUserToCheckOrderStatus: false,
        shouldCheckOrderStatus: Boolean(action.payload.shouldCheckOrderStatus),
        checkingOrderStatusDeadline: action.payload.checkingOrderStatusDeadline, 
        checkingOrderStatus: false,
        orderPlaced: false,
        orderPlacedReportedToPixels: false,
        placeOrderError:
          !action.payload.shouldCheckOrderStatus && action.payload.error,
        executePayment: false,
      };
    case ACTION_TYPE.SET_COUPONS_CHECKED:
      const batches = _.get(
        state.checkoutResponse,
        "couponBatchesResponse.batches",
        [],
      );
      const couponInstances = _.get(
        state.checkoutResponse,
        "couponBatchesResponse.couponInstances",
        [],
      );
      const {
        couponsBatchIndex,
        couponsUncheckedByUser,
        couponsCheckedByUser,
      } = state;

      const couponBatchesState = reduceByCouponsChecked(
        action.payload.checked,
        {
          batches,
          couponInstances,
          couponsBatchIndex,
          couponsUncheckedByUser,
          couponsCheckedByUser,
        },
      );

      return {
        ...state,
        ...couponBatchesState,
        giftCardsToRedeem: [],
      };

    case ACTION_TYPE.SET_CHARGE_CARD_DESELECTED:
      return {
        ...state,
        isChargeCardDeselected: action.payload.isChargeCardDeselected,
      };
    case ACTION_TYPE.START_REORDER:
      const isDelivery =
        _.get(action.payload.order, "deliveryAddress.tipAmount") !== undefined;
      const isCustomTip =
        (!isDelivery &&
          !_.get(action.payload.order, "orderTip.chosenTipPercentage")) ||
        (isDelivery &&
          !_.get(action.payload.order, "deliveryAddress.chosenTipPercentage"));
      return {
        ...initialState,
        savingRequestsTimestamps: [],
        lastChangeTimestamp: action.payload.timestamp,
        lastSaveTimestamp: null,
        ..._.pick(
          action.payload.order,
          "orderItems",
          "branchId",
          "servingOption",
          "deliveryAddress",
        ),
        servingOptionTipPercentage: !isDelivery
          ? isCustomTip
            ? "custom"
            : _.get(action.payload.order, "orderTip.chosenTipPercentage")
          : undefined,
        servingOptionTipCustomValue:
          !isDelivery && _.get(action.payload.order, "orderTip.tipAmount")
            ? _.get(action.payload.order, "orderTip.tipAmount")
            : 0,
        deliveryTipPercentage: isDelivery
          ? isCustomTip
            ? "custom"
            : _.get(action.payload.order, "deliveryAddress.chosenTipPercentage")
          : undefined,
        deliveryTipCustomValue:
          isDelivery && isCustomTip && _.get(action.payload.order, "deliveryAddress.tipAmount")
            ? _.get(action.payload.order, "deliveryAddress.tipAmount")
            : 0,
        servingOption: action.payload.order.servingOption,
        servingOptionType: action.payload.order.servingOption.type,
        reorder: true,
        futureServingTime: action.payload.firstAvailableDate,
      };
    case ACTION_TYPE.RESET_REORDER:
      return {
        ...state,
        reorder: false,
      };
    case ACTION_TYPE.RESET_EDIT_ITEM_MODE:
      return {
        ...state,
        editingItem: null,
      };
    case ACTION_TYPE.EDIT_ITEM_FROM_PAYMENT:
      return { ...state, editItemFromPayment: true };
    case ACTION_TYPE.SET_ORDER_FIXED_REMARKS:
      const fixedRemarks = _.clone(state.fixedRemarks);
      const indexOfFieldToUpdate = _.findIndex(
        fixedRemarks,
        (fixedRemark) =>
          fixedRemark.fixedRemarkId ===
          action.payload.fixedRemark.fixedRemarkId,
      );

      indexOfFieldToUpdate > -1
        ? (fixedRemarks[indexOfFieldToUpdate] = action.payload.fixedRemark)
        : fixedRemarks.push(action.payload.fixedRemark);

      return {
        ...state,
        fixedRemarks,
      };
    case ACTION_TYPE.RECHARGE_CARD.SUCCESS:
      return { ...state, lastChangeTimestamp: new Date().getTime() };
    case ACTION_TYPE.SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION:
      const isUpdatedLocationIdOrServingOption =
        action.payload.locationId !== state.branchId ||
        action.payload.servingOption.servingOptionType !==
          state.servingOptionType;
      const updatedOrderItems = _.map(state.orderItems, (item) => ({
        ...item,
        configuredMenuItemOrderData: {
          ...item.configuredMenuItemOrderData,
          menuItemServingOptions: {
            ...item.configuredMenuItemOrderData.menuItemServingOptions,
            selectedServingOption: {
              ...item.configuredMenuItemOrderData.menuItemServingOptions
                .selectedServingOption,
              selectedServingOptionId: action.payload.servingOption.id,
            },
          },
        },
      }));

      return {
        ...state,
        orderItems: updatedOrderItems,
        branchId: action.payload.locationId,
        lastChangeTimestamp: isUpdatedLocationIdOrServingOption
          ? action.payload.timestamp
          : state.lastChangeTimestamp,
        pickupTime:
          (isUpdatedLocationIdOrServingOption
            ? action.payload.pickupTime
            : state.pickupTime) || null,
        ...(isUpdatedLocationIdOrServingOption && {
          servingOptionTipPercentage: 0,
          deliveryTipPercentage: 0,
        }),
        futureServingTime:
          (isUpdatedLocationIdOrServingOption
            ? action.payload.futureServingTime
            : state.futureServingTime) || null,
        servingOption: action.payload.servingOption,
        servingOptionType: action.payload.servingOption.type,
        ...(action.payload.servingOption.needsAddress && {
          deliveryAddress: action.payload.userAddress,
        }),
        ...(_.isNumber(action.payload.defaultTip) &&
          (action.payload.servingOption.needsAddress
            ? {
                deliveryTipPercentage: action.payload.defaultTip / 100,
                servingOptionTipPercentage: 0,
              }
            : {
                servingOptionTipPercentage: action.payload.defaultTip / 100,
                deliveryTipPercentage: 0,
              })),
      };
    case ACTION_TYPE.GET_ORDER_STATUS.STARTED:
      return {
        ...state,
        checkingOrderStatus: true,
        shouldRequestUserToCheckOrderStatus: false,
      };
    case ACTION_TYPE.GET_ORDER_STATUS.SUCCESS:
      const orderStatusResponse = action.payload || {};
      const orderState = getOrderState(orderStatusResponse.checkoutResponse);
      console.log({ orderState });
      switch (orderState) {
        case ORDER_STATE.DONE:
          return {
            ...state,
            ...orderDoneState,
            lastOrderDetails: {
              ...action.payload,
              couponsBatchIndex: state.couponsBatchIndex,
              orderItems: state.orderItems,
              isChargeCardDeselected: state.isChargeCardDeselected,
              deliveryTipPercentage: state.deliveryTipPercentage,
              servingOptionTipPercentage: state.servingOptionTipPercentage,
            },
            orderPlaced: true,
            orderPlacedReportedToPixels: false,
            placingOrder: false,
            checkingOrderStatus: false,
            shouldCheckOrderStatus: false,
            shouldRequestUserToCheckOrderStatus: false,
            checkingOrderStatusDeadline: null,
          };
        case ORDER_STATE.PROCESSING:
          const shouldContinuePolling = Date.now() < state.checkingOrderStatusDeadline;
          return {
            ...state,
            orderPlaced: false,
            orderPlacedReportedToPixels: false,
            placingOrder: true,
            checkingOrderStatus: false,
            shouldCheckOrderStatus: shouldContinuePolling,
            shouldRequestUserToCheckOrderStatus: !shouldContinuePolling,
            checkingOrderStatusDeadline: shouldContinuePolling ? state.checkingOrderStatusDeadline : null,
          };
        case ORDER_STATE.CANCELED:
        default:
          return {
            ...state,
            shouldCheckOrderStatus: false,
            checkingOrderStatus: false,
            shouldRequestUserToCheckOrderStatus: false,
            placingOrder: false,
            orderPlaced: false,
            orderPlacedReportedToPixels: false,
            placeOrderError: UNKNOWN_ORDER_ERROR,
            checkingOrderStatusDeadline: null,
          };
      }

    case ACTION_TYPE.GET_ORDER_STATUS.FAILED:
      return {
        ...state,
        shouldRequestUserToCheckOrderStatus: true,
        checkingOrderStatus: false,
        placingOrder: true,
        orderPlaced: false,
        orderPlacedReportedToPixels: false,
        shouldCheckOrderStatus: false,
        checkingOrderStatusDeadline: null,
        placeOrderError: null,
      };
    case ACTION_TYPE.REPORT_ORDER_TO_PIXELS:
      return {
        ...state,
        orderPlacedReportedToPixels: true,
      };
    case ACTION_TYPE.ADD_PROMO_CODE:
      return _.isEmpty(
        _.differenceBy(action.payload.promoCodes, state.promoCodes, "code"),
      )
        ? state
        : {
            ...state,
            lastChangeTimestamp: action.payload.timestamp,
            promoCodes: _.concat(
              action.payload.promoCodes,
              _.filter(_.clone(state.promoCodes), { error: null }),
            ),
          };
    case ACTION_TYPE.REMOVE_PROMO_CODE:
      const codesWithoutErrors = _.map(
        _.filter(_.clone(state.promoCodes), { error: null }),
        (promoCode) => promoCode.code,
      );
      return {
        ...state,
        lastChangeTimestamp: action.payload.timestamp,
        promoCodes: _.filter(
          state.promoCodes,
          (promoCode) =>
            _.includes(codesWithoutErrors, promoCode.code) &&
            promoCode.code !== action.payload.promoCode,
        ),
      };
    case ACTION_TYPE.SET_SEARCH_CODE:
      return { ...state, searchCode: action.payload.searchCode };
    case ACTION_TYPE.SET_DELIVERY_TIP_CUSTOM_VALUE:
      return {
        ...state,
        ...(action.payload.deliveryTipCustomValue > 0 && {
          deliveryTipCustomValue:
            Number(action.payload.deliveryTipCustomValue) || 0,
        }),
      };
    case ACTION_TYPE.SET_SERVING_OPTION_TIP_CUSTOM_VALUE:
      return {
        ...state,
        ...(action.payload.servingOptionTipCustomValue > 0 && {
          servingOptionTipCustomValue:
            Number(action.payload.servingOptionTipCustomValue) || 0,
        }),
      };
    case ACTION_TYPE.SET_TABLE_CODE:
      return { ...state, tableCode: action.payload.tableCode };
    case ACTION_TYPE.RESET_TABLE_CODE:
      return { ...state, tableCode: null };
    case ACTION_TYPE.SET_ORDER_TIP_AMOUNT:
      return { ...state, orderTip: action.payload.orderTip };
    case ACTION_TYPE.SET_GIFT_CARDS_TO_REDEEM:
      return { ...state, giftCardsToRedeem: action.payload.giftCards };
    case ACTION_TYPE.FIRE_ORDER_EVENT:
      return { ...state, events: [...state.events, action.payload] };
    case ACTION_TYPE.SET_SERVING_OPTION_MANDATORY_FIELD:
      if (!_.isEmpty(action.payload.field)) {
        return {
          ...state,
          mandatoryTextFields: [
            ..._.filter(
              state.mandatoryTextFields,
              ({ field }) => field !== action.payload.field,
            ),
            action.payload,
          ],
        };
      }
      return state;
    case ACTION_TYPE.SYNC_ORDER_ITEMS:
      return {
        ...state,
        orderItems: action.payload,
        shouldSyncOrderItems: false,
      };
    case ACTION_TYPE.PAY_CHECK.STARTED:
      return {
        ...state,
        checkPaymentProcessing: action.payload.checkDetails,
        checkPaymentError: null,
        lastCheckDetails: null,
      };

    case ACTION_TYPE.PAY_CHECK.SUCCESS:
      return {
        ...state,
        checkPaymentProcessing: null,
        checkPaymentError: null,
        lastCheckDetails: action.payload.checkDetails,
      };

    case ACTION_TYPE.PAY_CHECK.FAILED:
      return {
        ...state,
        checkPaymentProcessing: null,
        checkPaymentError: action.payload.error,
      };
    
    case ACTION_TYPE.OPEN_PAYMENT.STARTED:
      return {
        ...state,
        openPaymentTokens: null
      }
    case ACTION_TYPE.OPEN_PAYMENT.FAILED:
      return {
        ...state,
        openPaymentTokens: null
      }
    case ACTION_TYPE.OPEN_PAYMENT.SUCCESS:
      return {
        ...state,
        openPaymentTokens: action.payload.openPaymentResponse
      }
    default:
      return state;
  }
};
