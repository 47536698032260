import postWithTextResponse from "../utils/postWithTextResponse";
import { post, get, deleteMethod } from "../utils/rest";
import SERVICE from "./constants";

export default class UserAPI {
  constructor(user, businessId) {
    this.businessId = businessId || process.env.GATSBY_BUSINESS_ID;
    this.user = user;
    if (user && user.userId) {
      this.authHeaders = {
        SESSION_ID: user.secretGUID,
        BUSINESS_ID: this.businessId,
        USER_ID: user.userId,
        MAC_ADDRESS: user.uniqueIdentifier,
      };
    }
  }

  login = (phoneNumber) =>
    post(`${SERVICE.USER}/login?businessId=${this.businessId}`, {
      phoneNumber,
      macAddress: this.user.uniqueIdentifier,
    });

  signup = ({ name, phoneNumber, email, birthday, signupGroupAlias, preferredLocations, recaptchaToken}) =>
    post(`${SERVICE.USER}/signup?businessId=${this.businessId}`, {
      name,
      phoneNumber,
      email,
      birthday,
      macAddress: this.user.uniqueIdentifier,
      signupGroupAlias,
      preferredLocations,
      recaptchaToken
    });

  addDeepLinkParamsToUser = ({deepLinkGroupHash}) => {
    if (this.user){
      return post(`${SERVICE.USER}/addDeepLinkParamsToUser?businessId=${this.businessId}`, {
          userId: this.user.userId,
          deepLinkGroupHash
        },
        this.authHeaders
      );
    }
    return null;
  }

  saveProfileDetails = ({ name, email, birthday, preferences, preferredLocations }) => {
    if (this.user) {
      return post(
        `${SERVICE.USER}/saveProfile?businessId=${this.businessId}`,
        {
          userId: this.user.userId,
          name,
          email,
          birthday,
          preferences,
          preferredLocations,
        },
        this.authHeaders,
      );
    } else {
      const error = "Cannot save user details when not logged in.";
      console.error(error);
      throw error;
    }
  };

  saveProfilePicture = ({ imageFormData }) => {
    if (this.user) {
      return fetch(
        `${SERVICE.MEDIA}/uploadUserProfileImage/userId=${
          this.user.userId
        }&businessId=${this.businessId}`,
        {
          headers: {
            ...this.authHeaders,
            // Accept: '',
            // 'Content-Type': 'application/json'
          },
          method: "POST",
          body: imageFormData,
        },
      );
    } else {
      const error = "Cannot save profile picture when not logged in.";
      console.error(error);
      throw error;
    }
  };

  verifyCode = ({ code, phoneNumber, branchId }) =>
    post(
      `${
        SERVICE.USER
      }/approveNewUsersCodeWithResponse?code=${code}&phonenumber=${phoneNumber}&businessId=${
        this.businessId
      }&platform=${
        branchId
          ? SERVICE.PLATFORM_IN_STORE_SIGNUP
          : window.isNativeApp
          ? SERVICE.PLATFORM_APP
          : SERVICE.PLATFORM_WEB
      }&branchId=${branchId}`,
    );

  // TODO: after deprecting Aroma-TLV former app filter accroding to real platform with window.isNative
  loadGifts = (userId) =>
    get(
      `${SERVICE.COUPON}/myCouponInstanceDetails?businessId=${
        this.businessId
      }&userId=${userId}&count=50&platform=${SERVICE.PLATFORM_WEB}`,
    );

  getChargeCardInstanceId = () =>
    get(
      `${SERVICE.CARD}/getOpenChargeCardInstanceForUser?userId=${
        this.user.userId
      }&businessId=${this.businessId}`,
      this.authHeaders,
    );

  donateGift = (charityId, giftId) =>
    post(
      `${SERVICE.COUPON}/donateCouponInstanceWithStats`,
      {
        businessId: this.businessId,
        fromUserId: this.user.userId,
        toCharityId: charityId,
        couponInstanceId: giftId,
      },
      this.authHeaders,
    );

  shareGift = ({
    fromUserName,
    personalMessage,
    couponInstanceId,
    toUserPhonenumber,
    toUserName,
  }) =>
    post(
      `${SERVICE.COUPON}/shareCouponInstance`,
      {
        personalMessage,
        couponInstanceId,
        fromUserId: this.user.userId,
        fromUserName,
        toUserPhonenumber,
        toUserName,
        businessId: this.businessId,
      },
      this.authHeaders,
    );

  markGiftSeen = (couponInstanceId) => {
    get(
      `${
        SERVICE.COUPON
      }/setCouponInstanceFirstSeen/couponInstanceId=${couponInstanceId}`,
      {
        ...this.authHeaders,
        "Content-Type": "text/plain",
      },
    );
  };

  loadLatestOrders = () => {
    return get(
      `${SERVICE.ORDER}/getLatestOrdersForUser?userId=${
        this.user.userId
      }&businessId=${this.businessId}`,
      {
        ...this.authHeaders,
        "Content-Type": "application/json",
      },
    );
  };

  loadHistory = () => {
    return get(
      `${SERVICE.CARD}/getUsersHistory?userId=${this.user.userId}&businessId=${
        this.businessId
      }`,
      {
        ...this.authHeaders,
        // 'Content-Type': 'application/json',
      },
    );
  };

  loadPurchase = (paymentId) => {
    return get(
      `${SERVICE.ORDER}/getOrderDetailsForPayment?userId=${
        this.user.userId
      }&paymentId=${paymentId}`,
      {
        ...this.authHeaders,
        "Content-Type": "application/json",
      },
    );
  };

  loadUserDetails = () =>
    get(
      `${SERVICE.USER}/getProfile?userId=${this.user.userId}&businessId=${
        this.businessId
      }`,
      this.authHeaders,
    );

  loadLoyaltyProfile = () =>
    get(
      `${SERVICE.USER}/getUserLoyaltyProfile?userId=${
        this.user.userId
      }&businessId=${this.businessId}`,
      this.authHeaders,
    );

  registerPushNotifications = ({
    pushIdentifier,
    os,
    isNewsApproved,
    isPurchaseApproved,
  }) =>
    post(
      `${SERVICE.USER}/addUserPushNotificationIdentifier`,
      {
        businessId: this.businessId,
        userId: this.user.userId,
        pushIdentifier: typeof pushIdentifier == "string" ? pushIdentifier : pushIdentifier && pushIdentifier.get("token") ? pushIdentifier.token : pushIdentifier,
        os: os === "ios" ? "iphone" : "android",
        isApprovedPushNotificationsForNews: isNewsApproved,
        isApprovedPushNotificationsForPurchase: isPurchaseApproved,
      },
      {
        ...this.authHeaders,
        Accept: "application/json",
      },
    );

  sendFeedback = (feedback) =>
    post(
      `${SERVICE.BUSINESS}/sendFeedbackToBusinessFromUser?businessId=${
        this.businessId
      }&userId=${this.user.userId}`,
      feedback,
    );

  getCashBackRemainder = () =>
    get(
      `${SERVICE.COUPON}/calcRemaindersForCashbackPolicies?businessId=${
        this.businessId
      }&userId=${this.user.userId}`,
      this.authHeaders,
    );

  getChargeCardBalance = () =>
    get(
      `${SERVICE.CARD}/getOpenChargeCardInstanceForUser?businessId=${
        this.businessId
      }&userId=${this.user.userId}`,
      { ...this.authHeaders },
    );

  updatePrivacyConsentApproval = (approved, privacyConsentId) =>
    postWithTextResponse(
      `${SERVICE.USER}/updatePrivacyConsentApproval`,
      {
        status: approved ? "APPROVED" : "REJECTED",
        //  statusUpdatedAt: new Date().getTime(),
        privacyConsentId,
        userId: this.user.userId,
        businessId: this.businessId,
      },
      this.authHeaders,
    );

  getUnseenGiftsAmount = () =>
    get(
      `${SERVICE.COUPON}/getNumMyUnseenCouponInstances/businessId=${
        this.businessId
      }&userId=${this.user.userId}`,
    );

  createGiftCard = (giftCardDetails) =>
    post(`${SERVICE.CARD}/createGiftCardPurchaseEvent`, {
      ...giftCardDetails,
      businessId: this.businessId,
      fromUserId: this.user.userId,
    });

  tradeBankPointsForCoupon = (couponId) =>
    post(
      `${SERVICE.COUPON}/tradeBankPointsForCouponForUser`,
      {
        couponId,
        userId: this.user.userId,
        businessId: this.businessId,
      },
      this.authHeaders,
    );

  getExternalGiftCards = (branchId) =>
    get(
      branchId
        ? `${SERVICE.USER}/getGiftCardsForUser?userId=${
            this.user.userId
          }&businessId=${this.businessId}&branchId=${branchId}`
        : `${SERVICE.USER}/getGiftCardsForUser?userId=${
            this.user.userId
          }&businessId=${this.businessId}`,
    );

  addExternalGiftCardToAccount = ({ cardNumber, cardPIN, userId }) =>
    post(
      `${SERVICE.USER}/addGiftCardToUser?userId=${
        this.user.userId ? this.user.userId : userId
      }&businessId=${this.businessId}`,
      {
        cardNumber,
        cardPIN,
      },
      this.authHeaders,
    );

  removeExternalGiftCardFromAccount = ({ cardNumber }) =>
    post(
      `${SERVICE.USER}/removeGiftCardFromUser?userId=${
        this.user.userId
      }&businessId=${this.businessId}`,
      {
        cardNumber,
      },
      this.authHeaders,
    );

  getWaitingOrders = (orderId) =>
    this.user.userId
      ? get(
          `${SERVICE.ORDER}/getWaitingOrdersForUser?userId=${
            this.user.userId
          }&businessId=${this.businessId}`,
          this.authHeaders,
        )
      : get(
          `${SERVICE.ORDER}/getWaitingOrders?orderIds=${orderId}&businessId=${
            this.businessId
          }`,
        );

  notifyIamHere = (orderId, { longitude, latitude } = {}) =>
    post(
      `${SERVICE.ORDER}/customerClickedImHere?orderId=${orderId}&businessId=${
        this.businessId
      }`,
      {
        longitude,
        latitude,
      },
      this.authHeaders,
    );

  archiveWaitingOrder = (orderId) =>
    post(
      `${SERVICE.ORDER}/archiveWaitingOrder?orderId=${orderId}&businessId=${
        this.businessId
      }`,
      {},
      this.authHeaders,
    );

  deleteAccount = () =>
    deleteMethod(
      `${SERVICE.USER}/deleteUser?userId=${this.user.userId}&businessId=${
        this.businessId
      }`,
      this.authHeaders,
    );

  saveSubscriptionForUser = (subscriptionParams) => {
      console.log("subscriptionParams", subscriptionParams);
      return post(
        `${SERVICE.USER}/saveSubscriptionForUser?userId=${this.user.userId}&businessId=${
          this.businessId
        }`,
        subscriptionParams,
        this.authHeaders,
      );
    }

    toggleFavoriteForUser = (favoriteParams) => {
      return post(
        `${SERVICE.USER}/toggleFavoriteForUser?userId=${this.user.userId}&businessId=${
          this.businessId
        }`,
        favoriteParams,
        this.authHeaders,
      );
    }

  unsubscribeForUser = (subscriptionParams) => {
    console.log("subscriptionParams", subscriptionParams);
    return post(
      `${SERVICE.USER}/unsubscribeForUser?userId=${this.user.userId}&businessId=${
        this.businessId
      }`,
      subscriptionParams,
      this.authHeaders,
    );
  }
}
